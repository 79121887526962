import React from 'react'
import BoxContent from './BoxContent'
import DashboardCard from './DashboardCard'
import logo from '../../assets/balance_logo.svg'

const Balance = ({ data }) => {
  return (
    <DashboardCard>
      <div className="header">
        <img alt="" src={logo} />
        Tu Balance
      </div>
      <BoxContent>
        <div className="data">
          <h1>
            <span>${data.balanceNu.toFixed(0)}</span>
            <br />
            <div className="data-name" style={{ minWidth: '8em' }}>
              Saldo {data.symbol}
            </div>
          </h1>
          <h1 style={{ marginTop: '-27px' }}>
            <span className="detail">≈ ${data.totalValANu.toFixed(0)}</span>
            <br />
            <span>${data.balanceANu.toFixed(0)}</span>
            <div className="data-name" style={{ minWidth: '8em' }}>
              Saldo {data.stakingSymbol}
            </div>
          </h1>
        </div>
      </BoxContent>
    </DashboardCard>
  )
}

export default Balance
