const ENVIROMENT_ADDRESSES = {
  mainnet: undefined,
  ropsten: undefined,
  rinkeby: undefined,
  bsc: [
    // ars
    {
      staking: '0x3E06DdFa41AAA6736695C6bfC84a8ce60Bd9ae6f',
      token: '0x91bc956F064d755dB2e4EfE839eF0131e0b07E28',
    },
    // pe
    {
      staking: '0xEF5563A72AF2AF5c360b9208b38CF9f3EE0E8941',
      token: '0xA5286319ABbc22cEB755afBD81DF55c1328dAdd1',
    },
  ],
  matic: [
    // ars
    {
      staking: '0x217f8d8522bd4864756c2ae6364e621bd4b2f067',
      token: '0x65517425ac3ce259a34400bb67ceb39ff3ddc0bd',
    },
    // pen
    {
      staking: '0x36f74ac9ff20c35e82dc5c90d209849b62f082b9',
      token: '0xe4c217d8eb681633eb670bcc2dc214a86f263138',
    },
    // cop
    {
      staking: '0x5a3dced5908a272826ce15ca747385b41c9f9e52',
      token: '0x0856f80ff4de8f2bf89872b27ba6e9fb45d96ae3',
    },
    // mxn
    {
      staking: '0x798134a2766cb0f1109317babac367907be1d28f',
      token: '0x60522c8cA1a3Db35Bf5014890d8F77E94c5Fa229',
    },
    // brl
    {
      staking: '0x974ab2481df0bfb58d74e2dc71ab5063720594de',
      token: '0x9c83B737048FC58d678e240468424b2d8eb12C3C',
    },
    // bhd
    {
      staking: '0xab0c36ad5e9e95764995064c35d655ead25dc698',
      token: '0x9aee7b988b08f3f0710f2a8f1d605640e0cda7a1',
    },
  ],
}

export default ENVIROMENT_ADDRESSES
