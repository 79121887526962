import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useWallet } from '../../providers/Wallet'
import Details from './Details'
import Balance from './Balance'
import Deposit from './Deposit'
import Redemption from './Redemption'
import { Contract as EthersContract } from 'ethers/lib/ethers'
import stakingAbi from '../../abi/staking.json'
import token from '../../abi/token.json'
import { formatUnits, fromPct } from '../../lib/math-utils'
import Card from './Card'
import { getStakingAddress, getTokenAddress } from '../../lib/addresses'
import { useLocation } from 'react-router-dom'
import themeArs from '../../theme'
import themePeru from '../../themePeru'

function Dashboard() {
  const { ethers, chainId, networkName, account } = useWallet()
  const [data, setData] = useState(null)
  const [intervalId, setIntervalId] = useState(undefined)
  const search = useLocation().search
  const coin = new URLSearchParams(search).get('coin') || '0'
  const theme = coin === '0' ? themeArs : themePeru

  const fetchData = async () => {
    if (!ethers) {
      console.log('No ethers')
      return
    }
    try {
      const signer = ethers.getSigner()
      const stakingAddress = getStakingAddress(networkName, search)
      const contractStaking = await new EthersContract(
        stakingAddress,
        stakingAbi,
        signer
      )

      const contractNum = await new EthersContract(
        getTokenAddress(networkName, search),
        token,
        signer
      )

      const [
        symbolNumToken,
        symbolStakingToken,
        balNu,
        balAnu,
        price,
        currentApy,
        isWhitelisted,
      ] = await Promise.all([
        contractNum.symbol(),
        contractStaking.symbol(),
        contractNum.balanceOf(account),
        contractStaking.balanceOf(account),
        contractStaking.rate(),
        contractStaking.apy(),
        contractStaking.isWhitelisted(account),
      ])

      const formattedBalAnu = formatUnits(balAnu)

      setData({
        ...data,
        symbol: symbolNumToken,
        stakingSymbol: symbolStakingToken,
        price: formatUnits(price),
        apy: fromPct(currentApy),
        balanceNu: formatUnits(balNu),
        balanceANu: formattedBalAnu,
        totalValANu: formattedBalAnu * formatUnits(price),
        isWhitelisted: isWhitelisted,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchData()
    if (intervalId !== undefined) {
      clearInterval(intervalId)
    }
    const id = setInterval(() => {
      fetchData()
    }, 10000)
    setIntervalId(id)
  }, [ethers, networkName, account])

  return (
    <ThemeProvider theme={theme}>
      <DashboardSection>
        {ethers ? (
          chainId === 137 ? (
            data ? (
              <>
                <Columns>
                  <Details data={data} />
                  <Balance data={data} />
                </Columns>
                {data.isWhitelisted ? (
                  <>
                    <Deposit data={data} fetch={fetchData} />
                    <Redemption data={data} fetch={fetchData} />
                  </>
                ) : (
                  <Card>
                    <h2>
                      Debes estar incluido en la whitelist para interactuar con
                      esta app
                    </h2>
                  </Card>
                )}
              </>
            ) : (
              <Card>
                <h2>Cargando...</h2>
              </Card>
            )
          ) : (
            <Card>
              <h2>Cambie a la red Polygon para continuar</h2>
            </Card>
          )
        ) : (
          <Card>
            <h2>Por favor, conecte una wallet para continuar</h2>
          </Card>
        )}
      </DashboardSection>
    </ThemeProvider>
  )
}

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`

const DashboardSection = styled.section`
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  h2 {
    font-family: 'Fraunces';
    font-size: 28px;
    padding-right: 30px;
  }
`

export default Dashboard
