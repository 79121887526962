import React, { useEffect, useState } from 'react'
import { Button, TextInput, useToast } from '@aragon/ui'
import { useWallet } from '../../providers/Wallet'
import { Contract as EthersContract } from 'ethers/lib/ethers'
import token from '../../abi/token.json'
import stakingAbi from '../../abi/staking.json'
import { formatUnits, fp } from '../../lib/math-utils'
import { constants } from 'ethers'
import DashboardCard from './DashboardCard'
import logo from '../../assets/deposit_logo.svg'
import MaxButton from './MaxButton'
import { getStakingAddress, getTokenAddress } from '../../lib/addresses'
import { useLocation } from 'react-router-dom'

const Deposit = ({ data, fetch }) => {
  const [amountToDeposit, setAmountToDeposit] = useState(0)
  const { ethers, networkName, account } = useWallet()
  const [allowance, setAllowance] = useState(null)
  const toast = useToast()
  const search = useLocation().search

  const loadAllowance = async () => {
    try {
      const signer = ethers.getSigner()
      const stakingAddress = getStakingAddress(networkName, search)

      const contractNum = await new EthersContract(
        getTokenAddress(networkName, search),
        token,
        signer
      )

      const allowance = await contractNum.allowance(account, stakingAddress)

      setAllowance(Number(formatUnits(allowance)))
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    loadAllowance()
  }, [])
  const approve = async () => {
    try {
      const signer = ethers.getSigner()
      const stakingAddress = getStakingAddress(networkName, search)

      const contractNum = await new EthersContract(
        getTokenAddress(networkName, search),
        token,
        signer
      )

      await contractNum.approve(stakingAddress, constants.MaxUint256)

      await loadAllowance()
      setTimeout(() => {
        loadAllowance()
        toast('Aprobado!')
      }, 10000)
    } catch (error) {
      console.log('error', error)
    }
  }
  const deposit = async () => {
    try {
      const signer = ethers.getSigner()
      const stakingAddress = getStakingAddress(networkName, search)
      const contractStaking = await new EthersContract(
        stakingAddress,
        stakingAbi,
        signer
      )

      await contractStaking.deposit(fp(amountToDeposit))
      setTimeout(() => {
        fetch()
        toast('Deposito realizado')
      }, 10000)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <DashboardCard style={{ marginBottom: '2rem' }}>
      <div className="header">
        <img alt="" src={logo} />
        Depositar
      </div>
      <div className="content-vertical">
        <TextInput
          wide
          style={{ width: '100%' }}
          value={Number(amountToDeposit || 0).toString()}
          type="number"
          onChange={event => {
            setAmountToDeposit(event.target.value || 0)
          }}
          adornment={
            <MaxButton
              max={data.balanceNu.toFixed(2)}
              onClick={() => setAmountToDeposit(data.balanceNu.toFixed(2))}
            />
          }
          adornmentPosition="end"
        />
        <p>
          {data.symbol} a depositar <span>$ {amountToDeposit}</span>
        </p>
        <p>
          {data.stakingSymbol} a recibir{' '}
          <span>$ {(amountToDeposit / data.price).toFixed(2)}</span>
        </p>
        {allowance < amountToDeposit ? (
          <Button onClick={approve}> Aprobar </Button>
        ) : (
          <Button onClick={deposit}> Depositar</Button>
        )}
      </div>
    </DashboardCard>
  )
}

export default Deposit
